// RADICAL IT - '_ANIMATIONS.SCSS' - IMPORTED INTO APP.SCSS

// GLOBAL TRANSITION SPEED FOR LINKS AND CALL TO ACTIONS
$transition-speed-links: 0.4s;
// --------

// GLOBAL TRANSITION SPEED FOR POST-IT AND POLAROID EFFECTS
$transition-speed-post-its: 0.3s;
// --------

@keyframes wiggle {
    0% { transform: rotate(0deg); }
   14% { transform: rotate(6deg); }
   29% { transform: rotate(-6deg); }
   43% { transform: rotate(4deg); }
   57% { transform: rotate(-4deg); }
   71% { transform: rotate(2deg); }
   86% { transform: rotate(-2deg); }
  100% { transform: rotate(0deg); }
}

@keyframes fadeIn {
    0% { opacity: 0; }
  100% { opacity: 1; }
}