@import 'colors';

$border-radius: 0.25rem;

.form {
  border: solid 1px $grey-mid;
  border-radius: $border-radius;
  .form-title {
    padding: 0.5rem 1rem;
    background-color: $grey-light;
    border-bottom: solid 1px $grey-mid;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .form_component {
    padding: 1rem;
    text-align: left;
    position: relative;
    label {
      display: block;
      margin-bottom: 0.25rem;
      font-size: 0.8rem;
      .is-required {
        font-weight: bold;
        font-size: 0.7rem;
        padding-left: 0.25rem;
      }
    }
    textarea {
      resize: vertical;
      margin-bottom: -0.25rem;
    }
    input, textarea {
      width: 100%;
    }
    select {
      width: auto;
    }
    input, textarea, select {
      border: solid 1px $grey-mid;
      border-radius: $border-radius;
      font-size: 0.9rem;
      &:focus {
        outline: none;
        box-shadow: 0 0 10px $blue-brand;
      }
    }
    &.preview-markdown {
      textarea {
        display: none;
      }
    }
    .md-preview {
      border: solid 1px $grey-mid;
      border-radius: $border-radius;
      font-size: 0.9rem;
      padding: 0.5rem;
    }
    .md-preview-toggle {
      position: absolute;
      top: 1rem;
      right: 1rem;
      text-decoration: none;
      font-size: 0.8rem;
      // TODO: Add an icon?
    }
    .form_component-error {
      display: none;
      position: absolute;
      bottom: 0;
      right: 1rem;
      color: $error;
      font-size: 0.7rem;
    }

    &.invalid {
      label {
        .is-required {
          color: $error;
        }
      }

      &.touched {
        label {
          color: $error;
        }

        input, textarea {
          border-color: $error;

          &:focus {
            box-shadow: 0 0 10px lighten($error, 15);
          }
        }

        .form_component-error {
          display: block;
        }
      }
    }
  }

  .form_buttons {
    margin-top: 0.5rem;
    border-top: solid 1px $grey-mid;
    background-color: $grey-light;
    padding: 0.5rem 1rem;
    text-align: right;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    button {
      margin-left: 1rem;
    }
  }
}
