@import 'colors';
@import 'blog-mixins';

.blog {
  position: relative;
  a {
    text-decoration: none;
    color: $blue-link;
    &:hover, &:focus, &:active {
      color: $blue-link-hover;
    }
  }
  .blog-entry {
    max-width: 680px;
    margin: 0 auto 1rem auto;
    opacity: 0;
    @include fadeIn();

    .blog-entry_title {
      padding: 0.5rem;
      font-size: 1rem;
      background-color: $grey-dark;
      color: $white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      .edit-post {
        display: inline-block;
        position: absolute;
        right: 0.5rem;
        color: $yellow-brand;
        // TODO: Pencil icon?
      }
    }

    .blog-entry_header, .blog-entry_body, .blog-entry_footer {
      font-size: 0.8rem;
      border: solid 1px $grey-dark;
      color: $black;

      > div {
        padding: 0.5rem;
      }
    }

    .blog-entry_header {
      border-bottom: none;
      border-top: none;

      .blog-entry_date, .blog-entry_time-to-read {
        color: $grey-dark;
        border-bottom: dashed 1px $grey-mid;
      }

      .blog-entry_date {
        border-right: dashed 1px $grey-mid;
        // TODO: Prefix the text with a calendar icon?
      }
      .blog-entry_time-to-read {
        font-style: italic;
        text-align: right;
        // TODO: Prefix the text with a clock icon?
      }
    }

    .blog-entry_body {
      border-bottom: none;
      border-top: none;
      white-space: pre-wrap;
    }
    
    .blog-entry_footer {
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      .blog-entry_posted {
        margin-top: 1rem;
        font-style: italic;
        font-size: 0.8rem;
        color: $grey-dark;
        border-top: dashed 1px $grey-mid;
      }
    }
  }

  .board {
    opacity: 0;
    @include fadeIn();
    padding-left: 0.5rem;
    margin-right: -0.5rem;
  }

  .blog-entry_wrapper {
    outline: none;
    cursor: pointer;
    opacity: 1;
  }
  .board.selection-made {
    .blog-entry_wrapper {
      &.deselected {
        opacity: 0;
        transition: opacity 1s;
      }
      &.selected {
        opacity: 0;
        transition: opacity 2s;
        .polaroid-wrapper, .post-it-wrapper {
          transform: scale(20);
          transition: all 2s;
        }
      }
    }
  }
}
