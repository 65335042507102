// RADICAL IT - '_NAVIGATION.SCSS' - IMPORTED INTO APP.SCSS
@import 'colors';

.navigation {
  display: block;
  font-size: 1rem;
  position: absolute;
  top: 31px;
  right: 0;
  ul {
    li {
      display: inline-block;
      a, .logout {
        cursor: pointer;
        color: $white;
        text-decoration: none;
        padding: 6px 12px;
        display: block;
        outline: none;
      }

      a {
        border-bottom: solid 6px transparent;
        &:hover, &:focus {
          color: $blue-brand;
        }
        &.active {
          border-bottom-color: $blue-brand;
          a:hover {
            color: $white;
            cursor: default;
          }
        }
      }

      .logout {
        color: $yellow-brand;
        &:hover, &:focus {
          color: $green-brand;
        }
      }
    }
  }
  @media screen and (max-width: 724px) {
    display: none;
    position: absolute;
    top: 65px;
    width: 100%;
    z-index: 999;
    background-color: $grey-dark;
    &.expanded {
      display: block;
    }
    ul {
      padding: 20px;
      li {
        display: block;
        border: none;
        margin-bottom: 10px;
        a, .logout {
          display: block;
          height: 40px;
          padding-top: 10px;
          padding-left: 0;
          transition: padding-left, 0.3s;
          border: none;
          &.active {
            background-image: url('../img/logo-badge.svg');
            background-repeat: no-repeat;
            background-size: 20px 20px;
            padding-left: 30px;
            background-position: 0 center;
            transition: padding-left 0.3s;
          }
        }
      }
    }
  }
}

// BURGER MENU
.nav-icon {
  margin: 1em;
  width: 20px;
  display: none;
  position: absolute;
  right: 10px;
  top: 0;
  cursor: pointer;
  @media screen and (max-width: 724px) {
      display: block;
  }
}
.nav-icon:after,
.nav-icon:before,
.nav-icon span {
  background-color: #fff;
  border-radius: 2px;
  content: '';
  display: block;
  height: 3px;
  margin: 5px 0;
  transition: all .5s ease-in-out;
}
.nav-icon:hover:before {
  transform: rotate(180deg);
}
.nav-icon:hover:after {
  transform: rotate(-180deg) ;
}
.nav-icon:hover div {
  transform: rotate(360deg) ;
}
// --------

