@import 'animations';
@import 'colors';
@import 'blog-mixins';

$post-it-size: 215px;
$post-it-margin: 15px;
$post-it-sticky-height: 60px;

.post-it-wrapper {
  font-family: 'Permanent Marker', monospace;
  margin-bottom: $post-it-margin;
  margin-right: $post-it-margin;
  float: left;
  position: relative;
  transition: all $transition-speed-post-its;
  .post-it {
    width: $post-it-size;
    max-width: $post-it-size;
    height: $post-it-size;
    max-height: $post-it-size;
    position: relative;
    padding: $post-it-sticky-height 15px 15px 15px;
    border: solid 1px $grey-light;
    font-size: 1.1rem;
    text-align: center;
    border-bottom-right-radius: 60px 5px;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      right: 0;
      bottom: 20px;
      width: 200px;
      height: 25px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 2px 15px 5px rgba(0, 0, 0, 0.4);
      transform: matrix(-1, -0.1, 0, 1, 0, 0);
    }

    .post-it_sticky {
      font-size: 1rem;
      position: absolute;
      height: $post-it-sticky-height;
      top: 0;
      left: 0;
      text-align: right;
      padding: 15px;
      width: 100%;
      color: $ttr-post-it;
    }
  }

  @include colourPostIt($yellow-gradient, $yellow-post-it-top);
  @include rotations(-7, 7);
}

.blog-entry_wrapper {
  @include zIndexes('.post-it-wrapper', 99);
  &:hover, &:focus {
    .post-it-wrapper {
      z-index: 100;
      box-shadow: 2px 2px 10px $grey-dark;
      transform: rotate(0deg);
    }
  }
  &:hover .post-it-wrapper {
    z-index: 101;
  }
  &:nth-child(even) {
    @include colourPostIt($blue-gradient, $blue-post-it-top);
  }
  &:nth-child(3n) {
    @include colourPostIt($green-gradient, $green-post-it-top);
  }
  &:nth-child(5n) {
    @include colourPostIt($pink-gradient, $pink-post-it-top);
  }
  &:first-child {
    .post-it {
      font-size: 1.5rem;
      width: ($post-it-size * 2) + $post-it-margin;
      max-width: ($post-it-size * 2) + $post-it-margin;
    }
  }
  &:last-child {
    margin-bottom: 60px;
  }
}
