@import 'colors';

.markdown {
  h1, h2 {
    margin-bottom: 1.5rem;
  }
  h1 + hr, h2 + hr {
    margin-top: -1rem;
    margin-bottom: 1.5rem;
  }
  p {
    margin: 1rem 0;
  }
  ol, ul {
    margin-left: 1rem;
  }
  ol li {
    list-style: decimal;
  }
  ul li {
    list-style: "‣  ";
  }
  img {
    margin: 0.25rem 0;
    display: inline-block;
    max-width: 100%;
  }

  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
}
