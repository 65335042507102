@import 'colors';

button {
  padding: 0.25rem 0.75rem;
  cursor: pointer;
  border: solid 1px $grey-dark;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &.primary {
    background-color: darken($blue-brand, 10);
    color: $white;
    &:hover, &:focus {
      background-color: $blue-brand;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 10px $blue-brand;
    }
    &:disabled {
      background-color: lighten($blue-brand, 10);
      border-color: $grey-mid;
    }
  }
  &.secondary {
    border-color: $grey-mid;
    background-color: $grey-light;
    &:hover, &:focus {
      background-color: $grey-mid;
    }
  }
}
