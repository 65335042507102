@import 'animations';
@import 'colors';
@import 'blog-mixins';

$polaroid-size: 215px;
$polaroid-wrapper-width: $polaroid-size;
$polaroid-wrapper-height: $polaroid-size;
$polaroid-width: ($polaroid-size * 1.05) - 10px;
$polaroid-height: ($polaroid-size * 1.05) + 20px;
$polaroid-margin: 15px;
$polaroid-footer-height: 30px;

.polaroid-wrapper {
  font-family: 'Permanent Marker', monospace;
  width: $polaroid-wrapper-width;
  max-width: $polaroid-wrapper-width;
  height: $polaroid-wrapper-height;
  max-height: $polaroid-wrapper-height;
  margin-bottom: $polaroid-margin;
  margin-right: $polaroid-margin;
  position: relative;
  float: left;
  .polaroid {
    position: absolute;
    top: 0;
    margin-left: -($polaroid-margin * 0.5);
    width: $polaroid-width;
    max-width: $polaroid-width;
    height: $polaroid-height;
    max-height: $polaroid-height;
    border: solid 1px $grey-light;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    background: $white;
    padding: 10px 10px $polaroid-footer-height 10px;
    text-align: center;
    transition: all $transition-speed-post-its;
    img {
      height: $polaroid-height - $polaroid-footer-height - 20px;
      width: $polaroid-height - $polaroid-footer-height - 20px;
      object-fit: cover;
      object-position: center;
      border: solid 1px $grey-light;
    }
    .polaroid_caption {
      position: absolute;
      bottom: 0;
      left: 0;
      width: $polaroid-width - 2px;
      text-align: center;
      padding: 0.25rem 0.5rem;
      line-height: 1rem;
      font-family: 'Permanent Marker', monospace;
      font-size: 1rem;
    }
  }
  .polaroid_ttr {
    position: absolute;
    top: 3px;
    color: $ttr-post-it;
    &:after {
      content: ' read';
    }
  }
  .tape {
    background-color: hsla(0, 0%, 100%, .2);
    box-shadow: inset 0 0 1em .5em hsla(0, 0%, 100%, .1);
    height: 2em;
    position: absolute;
    top: -1em;
    right: calc(50% - 4em);
    transform: rotate(-10deg);
    width: 6em;
    filter: drop-shadow(0 1px 1px hsla(0, 0%, 0%,.3));

    &:after, &:before {
      background-size: .4em .4em;
      bottom: 0;
      content: '';
      position: absolute;
      top: 0;
      width: .2em;
    }
    &:after {
      background-image: linear-gradient(45deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%),
      linear-gradient(-45deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%);
      background-position: 0 100%;
      left: -.2em;
    }
    &:before {
      background-image: linear-gradient(135deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%),
      linear-gradient(-135deg, transparent 50%, hsla(0, 0%, 100%, .3) 50%);
      background-position: 100% 100%;
      right: -.2em;
    }

    @include rotations(-30, 30);
  }
  @include rotations(-7, 7);
}

.blog-entry_wrapper {
  @include zIndexes('.polaroid-wrapper', 99);

  &:hover, &:focus {
    .polaroid-wrapper {
      z-index: 100;
      .polaroid {
        top: 4px;
        box-shadow: 2px 2px 10px $grey-dark;
      }
    }
  }
  &:hover .polaroid-wrapper {
    z-index: 101;
  }
}
