// We reverse order of z-indexes so the most recent
// has the highest z-index and appears on top.
@mixin zIndexes($child: null, $max: 99) {
  @for $i from 1 through $max {
    &:nth-child(#{$i}) {
      @if $child {
        #{$child} {
          z-index: $max - $i;
          @content;
        }
      } @else {
        z-index: $max - $i;
        @content;
      }
    }
  }
}

@mixin rotations($min: -7, $max: 7) {
  @for $i from $min through $max {
    &.rot-#{$i} {
      transform: rotate(#{$i}deg);
    }
  }
}

@mixin colourPostIt($background, $sticky) {
  .post-it {
    background: $background;
    .post-it_sticky {
      background: $sticky;
    }
  }
}

@mixin fadeIn() {
  animation: fadeIn ease 1s 1 forwards;
}