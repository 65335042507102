// RADICAL IT - '_TYPOGRAPHY.SCSS' - IMPORTED INTO APP.SCSS

@font-face {
  font-family: 'Permanent Marker';
  src: url('../typography/Permanent_Marker/PermanentMarker-Regular.ttf') format('truetype');
}

@font-face {
    font-family: lato-reg;
    font-style: normal;
    font-weight: 400;
    src: url('../typography/lato/lato-regular.ttf');
    src: local('?'),
    url('../typography/lato/lato-regular.ttf') format('truetype');
}
@font-face {
    font-family: lato-bold;
    font-style: normal;
    font-weight: 400;
    src: url('../typography/lato/lato-bold.ttf');
    src: local('?'),
    url('../typography/lato/lato-bold.ttf') format('truetype');
}
@font-face {
    font-family: lato-black;
    font-style: normal;
    font-weight: 400;
    src: url('../typography/lato/lato-black.ttf');
    src: local('?'),
    url('../typography/lato/lato-black.ttf') format('truetype');
}
@font-face {
    font-family: lato-light;
    font-style: normal;
    font-weight: 400;
    src: url('../typography/lato/lato-light.ttf');
    src: local('?'),
    url('../typography/lato/lato-light.ttf') format('truetype');
}

body, html, p, div, h1, h2, h3, h4, h5, h6, li, td, button, span {
    font-size: 1.1rem;
    font-family: lato-reg, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

div.post-it-wrapper .post-it,
div.post-it-wrapper .post-it_sticky {
    font-family: Permanent Marker, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

