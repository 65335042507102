// RADICAL IT - '_LINKS-CTAS.SCSS' - IMPORTED INTO APP.SCSS
@import 'colors';
@import 'animations';

a, button {
  transition: background-color, color $transition-speed-links;
  -moz-transition: background-color, color $transition-speed-links;
  -webkit-transition: background-color, color $transition-speed-links;
  -o-transition: background-color, color $transition-speed-links;
  &:hover, &:focus, &:active {
    transition: background-color, color $transition-speed-links;
    -moz-transition: background-color, color $transition-speed-links;
    -webkit-transition: background-color, color $transition-speed-links;
    -o-transition: background-color, color $transition-speed-links;
  }
}
