// RADICAL IT - '_HEADER.SCSS' - IMPORTED INTO APP.SCSS
@import 'colors';

header {
  background-color: $grey-dark;
  border-bottom: 6px solid $grey-mid;
  .header-content {
    font-size: 0.8rem;
    padding: 10px;
    color: $white;
    position: relative;
    img {
      height: 40px;
      width: auto;
    }
  }
  // NAVIGATION
  @import '_navigation';
}
