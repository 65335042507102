@import 'animations';
@import 'blog-mixins';
@import 'colors';
@import 'forms';
@import 'login';
@import 'post-it';
@import 'polaroid';

.admin {
  position: relative;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: black;

    @include zIndexes('.post-it-wrapper', 99) {
      .post-it {
        padding-top: calc(#{$post-it-size / 2} - 1rem);
      }
    }
    .post-it-wrapper.red {
      @include colourPostIt($pink-gradient, $pink-post-it-top);
    }
    .post-it-wrapper.blue {
      @include colourPostIt($blue-gradient, $blue-post-it-top);
    }
    &:hover, &:focus {
      outline: none;
      .post-it-wrapper {
        z-index: 100;
        box-shadow: 2px 2px 10px $grey-dark;
        transform: rotate(0deg);
      }
    }
    @include rotations(-7, 7);
  }
  .menu {
    max-width: ($post-it-size + $post-it-margin) * 3;
    margin: auto;
  }
  .button-bar {
    padding-bottom: 0.5rem;
    border-bottom: dashed 1px $grey-mid;
    margin-bottom: 1rem;
    a {
      color: $blue-brand;
      border: solid 1px $blue-brand;
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      float: left;
      &.new-post {
        &:after {
          content: " +"
          // TODO: Add icon instead of "+"
        }
      }
      &:hover, &:active, &:focus {
        color: darken($blue-brand, 15);
        background-color: lighten($blue-brand, 25);
        transition: all $transition-speed-links;
        box-shadow: 2px 2px 10px lighten($blue-brand, 15);
      }
    }
    > .right {
      float: right;
    }
  }
  .blog-admin, .files-admin {
    .post-listing, .files-listing {
      a {
        .row {
          background-color: $white;
        }
        &:nth-child(odd) {
          .row {
            background-color: $grey-light;
          }
        }
        &:hover, &:focus {
          color: darken($blue-brand, 25);
          transition: all $transition-speed-links;
          .row {
            background-color: lighten($blue-brand, 15);
            transition: all $transition-speed-links;
          }
        }
      }
      .listing-header {
        background-color: $grey-dark;
        color: $white;
        a {
          color: $yellow-brand;
        }
      }
      .col {
        padding: 0.5rem;
        &.status {
          text-transform: capitalize;
          text-align: right;
        }
      }
    }
    .post-listing {
      border: solid 1px $grey-light;
    }
    .files-listing {
      .listing-content {
        margin-top: 1.5rem;
        padding-left: $polaroid-margin * 1;
      }
      .polaroid-wrapper {
        @include zIndexes(null, 99);
        margin-bottom: $polaroid-margin * 3;
        .polaroid {
          margin-left: 0;
        }
        .polaroid_caption {
          font-family: merriweather, 'Arial', serif;
        }
        .tape_replace {
          display: none;
          color: $error;
          font-size: 1.4rem;
        }
        &:hover, &:focus {
          z-index: 100;
          .polaroid {
            top: 4px;
            box-shadow: 2px 2px 10px $grey-dark;
          }
          .tape_replace {
            display: inline-block;
          }
        }
      }
    }
  }
}

.blog-post {
  position: relative;
  text-align: center;

  .form {
    width: 100%;
    max-width: 700px;
    display: inline-block;
    box-shadow: 2px 2px 10px $grey-mid;

    .form-title {
      text-align: left;
    }

    .save-error {
      display: block;
      padding: 0.5rem;
      color: $white;
      font-size: 0.8rem;
      border: solid 1px $error;
      background-color: $error;
      // TODO: Add icon?
    }

    .save-success {
      display: block;
      padding: 0.5rem;
      font-size: 0.8rem;
      border: solid 1px $green-brand;
      background-color: $green-brand;
      // TODO: Add icon?
    }
  }

  &.save-failed {
    .form {
      border-color: $error;
      box-shadow: 2px 2px 10px lighten($error, 15);
      .form-title {
        background-color: $error;
        color: $white;
        border-bottom: dashed 1px $white;
      }
    }
  }

  &.save-succeeded {
    .form {
      border-color: $green-brand;
      box-shadow: 2px 2px 10px lighten($green-brand, 15);
      .form-title {
        background-color: $green-brand;
        border-bottom: dashed 1px $white;
      }
    }
  }
}
