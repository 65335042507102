// RADICAL IT - '_LAYOUT.SCSS' - IMPORTED INTO APP.SCSS

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
td,
tr,
ul,
ol,
li,
hr,
div,
span,
img,
label {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style: none;
}

input, textarea, select {
  box-sizing: border-box;
  padding: 0.25rem;
}

.container {
  padding: 20px;
  margin-bottom: 20px;
}

.clearfix {
  &:after {
    content: "";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}
.content-home {
  margin-top: 300px;
}

.banner {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 69px;
  background-image: url('/images/home-placeholder-background.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  margin-bottom: 20px
}
