// RADICAL IT - '_FOOTER.SCSS' - IMPORTED INTO APP.SCSS
@import 'animations';
@import 'colors';

footer {
  position: fixed;
  bottom: 0;
  background-color: $grey-mid;
  border-top: solid 2px $grey-dark;
  width: 100%;
  z-index: 999;
  .footer-content {
    font-size: 0.8rem;
    padding: 10px;
  }
  .admin-link {
    .marker {
      transition: all 1s cubic-bezier(.47, 1.64, .41, .9);
      position: absolute;
      right: -400px;
      top: -41px;
      width: auto;
      height: 40px;
      transform: rotate(-0.7deg);

      &.show {
        right: 20px;
      }
    }
    &:hover, &:focus, &:active {
      .marker {
        animation: wiggle 0.7s;
      }
    }
  }
}
