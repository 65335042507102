// RADICAL IT - '_GRID.SCSS' - IMPORTED INTO APP.SCSS

// Breakpoints for different sizes.
$grid-bp-md: 768px;
$grid-bp-lg: 980px;
$grid-bp-xl: 1200px;

// Space between columns when the row is not "collapsed".
$grid-spacing: 1rem;

@mixin grid_at_breakpoint($bp) {
  $width: $grid-bp-md;
  @if $bp == 'lg' {
    $width: $grid-bp-lg;
  } @else if $bp == 'xl' {
    $width: $grid-bp-xl;
  }
  @media only screen and (min-width: $width) {
    @include columns($bp);
  }
}

// It's all based on 12 columns.
$columns: 12;

@mixin columns($prefix) {
  // Sort out the widths for each column.
  @for $i from 1 through $columns {
    &.#{$prefix}-#{$i} {
      width: ($i / $columns) * 100%;
    }
  }
}

.row, .row-c {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -$grid-spacing;
  margin-right: -$grid-spacing;

  .col {
    padding-left: $grid-spacing;
    padding-right: $grid-spacing;

    // Small / default widths.
    @include columns('sm');
    // Set up the media queries for other sizes.
    @include grid_at_breakpoint('md');
    @include grid_at_breakpoint('lg');
    @include grid_at_breakpoint('xl');
  }
}

.row-c, .row.collapsed {
  margin-left: 0;
  margin-right: 0;
  .col {
    padding-left: 0;
    padding-right: 0;
  }
}
