@import 'colors';

.login {
  position: relative;
  text-align: center;
  .form {
    width: 300px;
    max-width: 300px;
    display: inline-block;
    box-shadow: 2px 2px 10px $grey-mid;

    .login-error {
      display: block;
      padding: 0.5rem;
      color: $white;
      font-size: 0.8rem;
      border: solid 1px $error;
      background-color: $error;
    }
  }

  &.login-failed {
    .form {
      border-color: $error;
      box-shadow: 2px 2px 10px lighten($error, 15);
      .form-title {
        background-color: $error;
        color: $white;
        border-bottom: dashed 1px $white;
      }
    }
  }
}
