// RADICAL IT - '_COLORS.SCSS' - IMPORTED INTO APP.SCSS

// WHITES
$white: #fff;

// BLACKS
$black: #111;

// GREYS
$grey-dark: #333;
$grey-mid: #ccc;
$grey-light: #eee;

// BRAND COLOURS
$blue-brand: #669aff;
$yellow-brand: #ffd942;
$green-brand: #99cd33;

// POST-IT NOTES
$blue-post-it: #99ffff;
$blue-post-it-top: darken($blue-post-it, 5);
$blue-gradient: linear-gradient(135deg, #99ffff 81%,#99ffff 82%,#99ffff 82%, #c6ffff 100%);
$yellow-post-it: #ffff99; // Canary
$yellow-post-it-top: darken($yellow-post-it, 5);
$yellow-gradient: linear-gradient(135deg, #ffff99 81%,#ffff99 82%,#ffff99 82%,#ffffc6 100%);
$green-post-it: #ccffcc;
$green-post-it-top: darken($green-post-it, 2);
$green-gradient: linear-gradient(135deg, #ccffcc 81%,#ccffcc 82%,#ccffcc 82%,#ddffdd 100%);
$pink-post-it: #ffaacc;
$pink-post-it-top: darken($pink-post-it, 2);
$pink-gradient: linear-gradient(135deg, #ffaacc 81%,#ffaacc 82%,#ffaacc 82%,#ffbbdd 100%);

$ttr-post-it: #cc0000;

// RAG STATUS COLOURS
$error: #bb4444;

// LINK COLOURS (ON BLOG)
$blue-link: darken($blue-brand, 20);
$blue-link-hover: darken($blue-brand, 35);
