@import 'colors';

.upload-file {
  position: relative;
  cursor: pointer;

  input {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 98;
    opacity: 0;
    width: 1px;
  }

  a {
    color: $blue-brand;
    border: solid 1px $blue-brand;
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    &:hover, &:active, &:focus {
      color: darken($blue-brand, 15);
      background-color: lighten($blue-brand, 25);
      transition: all $transition-speed-links;
      box-shadow: 2px 2px 10px lighten($blue-brand, 15);
    }
  }
}


.files-admin {
  .files-listing {
    .listing-content {
      text-align: center;
      align-items: stretch;
      .col {
        position: relative;
        vertical-align: bottom;
        label {
          display: block;
          width: 100%;
          background: $grey-dark;
          color: $white;
          border: solid 1px $grey-dark;
          padding: 0.25rem 0.5rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        img.thumbnail {
          margin: 0 auto;
          display: block;
          background: $white;
          border: solid 1px $grey-mid;
          width: 100%;
          vertical-align: bottom;
          &.document {
            padding: 0.5rem 0;
          }
        }
      }
    }
  }
}
